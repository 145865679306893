import React from "react"
import ImageRenderer from "../atoms/ImageRenderer"
import LinkComponent from "../atoms/Link"

const BusinessCard = ({
  businessCardEC,
  image,
  title,
  titleEC,
  description,
  descriptionEC,
  button,
}) => {
  return (
    <div className={"flex flex-col gap-5 sm:gap-7 " + businessCardEC || ""}>
      <div className="busness-by-industry--card-image">
        <ImageRenderer img={image.path} alt={image.alt} />
      </div>
      <div className="px-2 flex flex-col gap-3 sm:gap-4 pb-5">
        <h2
          className={
            "text-[18px] sm:text-[22px] lg:text-[26px] font-[500] text-[#2A2A2A] leading-[1.2em] " +
              titleEC || ""
          }
        >
          {title}
        </h2>
        <div>
          <p
            className={
              "text-[16px] md:text-[18px] text-[#2A2A2A] leading-[1.4em] " +
                descriptionEC || ""
            }
          >
            {description}
          </p>
          {button && (
            <LinkComponent
              to={button?.to || "/"}
              href={button?.href || ""}
              text={button?.text || ""}
              ec={
                button?.ec + " underline leading-7 !mt-0 !text-[#B695F8]" || ""
              }
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default BusinessCard
