import React from "react"
import PageSectionHeader from "../../common/PageSectionHeader"
import CTASectionWithImage from "../../molecules/CTASectionWithImage"

const BenefitsSection = ({ title, description, benefits }) => {
  return (
    <div className="synkli-section--horizontal-space synkli-section--vertical-space">
      <PageSectionHeader
        subTitle={`BENEFITS`}
        title={title}
        titleEC={`max-w-[1200px]`}
        description={description}
        descriptionEC={`max-w-[800px] mx-auto`}
      />
      <div className="mt-16">
        {benefits.map((benefit, index) => (
          <CTASectionWithImage
            key={"benefit_" + index}
            componentEC={benefit.componentEC + " mt-24"}
            title={benefit.title}
            description={benefit.description}
            buttonn={{
              to: benefit.buttonn.to,
              href: benefit.buttonn.href,
              text: benefit.buttonn.text,
              ec: benefit.buttonn.ec,
            }}
            image={{
              path: benefit.image.path,
              alt: benefit.image.alt,
            }}
            hoveredImage={{
              path: benefit.hoveredImage.path,
              alt: benefit.hoveredImage.alt
            }}
          />
        ))}
      </div>
    </div>
  )
}

export default BenefitsSection
