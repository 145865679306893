// Hero Section
export const heroSection = {
  title: `All-In-One <span class="text-secondary">Accounting Solution</span> For Businesses and Individuals`,
  description: `Providing comprehensive accounting solutions to all businesses and individuals. Effortlessly manage your finances, streamline deductions, maximise your tax returns and improve your overall productivity.`,
  image: {
    path: `small-business-hero-image`,
    alt: `small-business-hero-image`
  }
}

// Problems Section
export const problemSection = {
  title: `Common Accounting Problems Faced by Businesses and Individuals`,
  description: `Businesses and salaried individuals face challenges in accounting and financial management. If these problems are not addressed efficiently, they can cause costly mistakes in the future.`,
  topProblems: [
    {
      title: "Unclaimed tax deductions",
      description:
        "Missing some of your tax deduction claims reduces your overall tax refund, causing you to lose a lot of money.",
      image: {
        path: "problem---unclaimed-tax-deductions",
        alt: "problem---unclaimed-tax-deductions",
      },
    },
    {
      title: "Lack of cash flow and expense visibility",
      description:
        "This gives an unclear picture of your finances, making it hard to make informed decisions.",
      image: {
        path: "problem---lack-of-cash-flow-and-expense-visibility",
        alt: "problem---lack-of-cash-flow-and-expense-visibility",
      },
    },
    {
      title: "Difficulty calculating payable GST",
      description:
        "Accurate GST calculation is crucial for Australian businesses to comply with tax regulations and avoid penalties.",
      image: {
        path: "problem---difficulty-calculating-payable-gst",
        alt: "problem---difficulty-calculating-payable-gst",
      },
    },
    {
      title: "Poor management of receipts",
      description:
        "Managing business receipts for a year is challenging, and misplaced receipts can lead to missed refunds.",
      image: {
        path: "problem---poor-management-of-receipts",
        alt: "problem---poor-management-of-receipts",
      },
    },
    {
      title: "Uncertainties in eligible claims",
      description:
        "Lack of knowledge of what you are eligible to claim as a business and an individual makes you lose out on potential tax refunds.",
      image: {
        path: "problem---uncertainties-in-eligible-claims",
        alt: "problem---uncertainties-in-eligible-claims",
      },
    },
    {
      title: "Inefficient expense and income management",
      description:
        "A business with poor tracking of income and expenses is at risk of inaccurate financial reporting.",
      image: {
        path: "problem---inefficient-expense-and-income-management",
        alt: "problem---inefficient-expense-and-income-management",
      },
    },
  ],
  bottomProblems: [
    {
      title: "Lack of centralised data",
      description:
        "Your data on different platforms makes it hard to access and manage effectively. This can lead to confusion and frustration.",
      image: {
        path: "problem---lack-of-centralised-data",
        alt: "problem---lack-of-centralised-data",
      },
    },
    {
      title: "Disorganised file management",
      description:
        "Disorganised documents can lead to wrong financial decisions and wasted time. This will affect your productivity.",
      image: {
        path: "problem---disorganised-file-management",
        alt: "problem---disorganised-file-management",
      },
    },
    {
      title: "Inadequate communication with accountants",
      description:
        "Your accountant needs to have your business information at the right time.",
      image: {
        path: "problem---inadequate-communication-with-accountants",
        alt: "problem---inadequate-communication-with-accountants",
      },
    },
    {
      title: "Trouble signing documents",
      description:
        "There’s always a need to sign documents, and not having an easy way to do so will be a time-consuming task.",
      image: {
        path: "problem---trouble-signing-documents",
        alt: "problem---trouble-signing-documents",
      },
    },
    {
      title: "Inefficient mileage tracker",
      description:
        "It is always difficult to keep records of both personal and work-related trips.",
      image: {
        path: "problem---inefficient-mileage-tracker",
        alt: "problem---inefficient-mileage-tracker",
      },
    },
  ],
}

// Solutions Section
export const solutionsSection = {
  title: `Solutions SYNKLI Offers Businesses and Individuals`,
  description: `Synkli is designed to solve most of your pressing accounting challenges. Its comprehensive features simplify every aspect of financial management to ensure accurate record-keeping.`,
  firstColumnSolutions: [
    {
      SolutionCardEC: "",
      title: "Expense and income management",
      description:
        "Manage your income and expenses effectively without leaving your house with Synkli. With this app, you can keep adequate records of all your transactions.",
      button: {
        to: "/",
        href: "#",
        text: "See More",
      },
      image: {
        path: "solution---expense-and-income-management",
        alt: "solution---expense-and-income-management",
      },
      hoveredImage: {
        path: "solution---expense-and-income-management--hovered",
        alt: "solution---expense-and-income-management--hovered",
      },
    },
    {
      SolutionCardEC: "!mr-0 mt-10 md:mt-0 lg:mt-10",
      title: "Clear cash flow and expense visibility",
      description:
        "Our easy-to-use app lets you track your cash flow in real time and input your expenses accordingly.",
      button: {
        to: "/",
        href: "#",
        text: "See More",
      },
      image: {
        path: "solution---clear-cash-flow-and-expense-visibility",
        alt: "solution---clear-cash-flow-and-expense-visibility",
      },
      hoveredImage: {
        path: "solution---clear-cash-flow-and-expense-visibility--hovered",
        alt: "solution---clear-cash-flow-and-expense-visibility--hovered",
      },
    },
  ],
  secondColumnSolutions: [
    {
      SolutionCardEC: "",
      title: "Claim deductions",
      description:
        "Experience a new way to digitise and organise your expense receipts using Synkli. With the app, you can easily snap and let the app extract all the necessary data from your receipts and save them to make claiming your deductions easy.",
      button: {
        to: "/",
        href: "#",
        text: "See More",
      },
      image: {
        path: "solution---claim-deductions",
        alt: "solution---claim-deductions",
      },
      hoveredImage: {
        path: "solution---claim-deductions--hovered",
        alt: "solution---claim-deductions--hovered",
      },
    },
    {
      SolutionCardEC: "!mr-0 mt-10 md:mt-0 lg:mt-10",
      title: "Centralised solutions",
      description:
        "Centralise all your financial data in one place for easy access anytime. This will help you organise your data better and make informed decisions.",
      button: {
        to: "/",
        href: "#",
        text: "See More",
      },
      image: {
        path: "solution---centralised-solutions",
        alt: "solution---centralised-solutions",
      },
      hoveredImage: {
        path: "solution---centralised-solutions--hovered",
        alt: "solution---centralised-solutions--hovered",
      },
    },
  ],
  thirdColumnSolutions: [
    {
      SolutionCardEC: "",
      title: "Exclusive file manager",
      description:
        "Organise and store all your business files and documents efficiently in the app. You can store, sign, and share anytime.",
      button: {
        to: "/",
        href: "#",
        text: "See More",
      },
      image: {
        path: "solution---exclusive-file-manager",
        alt: "solution---exclusive-file-manager",
      },
      hoveredImage: {
        path: "solution---exclusive-file-manager--hovered",
        alt: "solution---exclusive-file-manager--hovered",
      },
    },
    {
      SolutionCardEC: "!mr-0 mt-10 md:mt-0 lg:mt-10",
      title: "Efficient mileage tracker",
      description:
        "Synkli uses technology to track your business and personal trips automatically. This ensures accurate mileage records for tax deductions, as you can easily categorise your personal and business trips.",
      button: {
        to: "/",
        href: "#",
        text: "See More",
      },
      image: {
        path: "solution---efficient-mileage-tracker",
        alt: "solution---efficient-mileage-tracker",
      },
      hoveredImage: {
        path: "solution---efficient-mileage-tracker--hovered",
        alt: "solution---efficient-mileage-tracker--hovered",
      },
    },
  ],
}

// Small Business Section
export const smallBusinessSection = {
  subTitle: `SMALL BUSINESSES`,
  title: `Simplify Your Business Management With Our Simple to Use Accounting Software`,
  description: `Business owners should not face problems when it comes to their financial responsibilities and claiming all their business deductions. With just one app and one download, you can perform several accounting activities like a PRO.`,
  CTATitle: `How do we make this happen?`,
  CTADescription: `Synkli is an app specifically designed to cater to the needs of business owners. We understand how frustrating it is to claim all your business deductions and have easy access to your accountants. With Synkli, you can claim your business deductions easily, communicate with your accountant, and keep records seamlessly, all with a simple to use interface.`,
  CTAImage: {
    path: "small-business--businesses-section-logos",
    alt: "small-business--businesses-section-logos",
  },
  hoveredImage: {
    path: `small-business--businesses-section-logos`,
    alt: `small-business--businesses-section-logos`
  }
}

// Individuals Section
export const individualsSection = {
  subTitle: `INDIVIDUALS`,
  title: `With No Knowledge of Accounting, Streamline Record Keeping of Your Day to Day Activities`,
  description: `As an individual with no experience in accounting, can you be a PRO by using Synkli as your go-to accounting app?`,
  CTATitle: `Never miss a tax deduction again`,
  CTADescription: `Did you know that you can claim all your personal income deductions with Synkli? Our app is designed to streamline your expense tracking process to ensure that every eligible deduction is captured and accounted for. With features like receipt scanning using OCR technology, real-time expense tracking, and easy data sharing with your accountant, you can rest assured that every deduction can be noticed.`,
  CTAImage: {
    path: "small-business--individual-section-logos",
    alt: "small-business--individual-section-logos",
  },
  hoveredImage: {
    path: `small-business--individual-section-logos-hovered`,
    alt: `small-business--individual-section-logos-hovered`
  }
}

// Benefits Section
export const benefitsSection = {
  title: `Discover Synkli: The Ultimate Accounting Solution For Businesses and Individuals`,
  description : `Experience an app with all the necessary accounting features for your business and individual financial needs.`,
  benefits : [
    {
      componentEC: "",
      title: "Scan and Go",
      description:
        "Scan your business receipts on the go with Synkli’s OCR technology. This feature allows you to easily save and organise the information on the receipts automatically in your software. ",
      buttonn: {
        to: "",
        href: "#",
        text: "Learn More",
        ec: "",
      },
      image: {
        path: "small-business--scan-and-go",
        alt: "small-business--scan-and-go",
      },
      hoveredImage: {
        path: `small-business--scan-and-go-hovered`,
        alt: `small-business--scan-and-go-hovered`
      }
    },
    {
      componentEC: "flex-col md:flex-row-reverse",
      title: "Clear cash flow and expense visibility",
      description:
        "Gain clarity on your business cash flow and expenses with our accounting solution application. Access your profit and loss statements anytime you want to consolidate all transactions in a place quickly.",
      buttonn: {
        to: "",
        href: "#",
        text: "Learn More",
        ec: "",
      },
      image: {
        path: "small-business--clear-cash-flow-and-expense-visibility",
        alt: "small-business--clear-cash-flow-and-expense-visibility",
      },
      hoveredImage:{
        path: `small-business--clear-cash-flow-and-expense-visibility-hovered`,
        alt: `small-business--clear-cash-flow-and-expense-visibility-hovered`
      }
    },
    {
      componentEC: "",
      title: "Send summary to your accountant",
      description:
        "Effortlessly send comprehensive live data summaries to promote easy collaboration and accurate financial management. Your summary can include all expenses, income, and deductions peculiar to your business. This makes it easy for your accountant to understand your business's financial situation.",
      buttonn: {
        to: "",
        href: "#",
        text: "Learn More",
        ec: "",
      },
      image: {
        path: "small-business--send-summary-to-your-accountant",
        alt: "small-business--send-summary-to-your-accountant",
      },
      hoveredImage: {
        path: `small-business--send-summary-to-your-accountant-hovered`,
        alt: `small-business--send-summary-to-your-accountant-hovered`
      }
    },
  ]
}

// Business By Industry Section
export const businessByIndustrySection = {
  subTitle: `Business`,
  title: `Industries That Can Use The Synkli App`,
  industries : [
    {
      image: {
        path: "business-by-industry--trading",
        alt: "business-by-industry--trading",
      },
      title: "Freelancers and Consultants",
      description:
        "Streamline your expense tracking and income management with the Synkli app. Get all your business expenses with features like receipt scanning, expense and income recording, and effortless collaboration with your accountants.",
      buttonn: {
        to: "/",
        href: "#",
        text: "See More",
      },
    },
    {
      image: {
        path: "business-by-industry--construction",
        alt: "business-by-industry--construction",
      },
      title: "Construction",
      description:
        "Track your project expenses and income using the Synkli app. You can also easily claim all your deductible work deductions by having all your work receipts scanned and saved automatically.",
      buttonn: {
        to: "/",
        href: "#",
        text: "See More",
      },
    },
    {
      image: {
        path: "business-by-industry--manufacturing",
        alt: "business-by-industry--manufacturing",
      },
      title: "Healthcare professionals",
      description:
        "Monitor your monthly expenses and track income with Synkli. You can experience substantial tax refunds by having all your receipts recorded and your deductions claimed.",
      buttonn: {
        to: "/",
        href: "#",
        text: "See More",
      },
    },
    {
      image: {
        path: "business-by-industry--real-estate",
        alt: "business-by-industry--real-estate",
      },
      title: "Real estate agents",
      description:
        "Track your property expenses and rental income with our simple to use app. Easily calculate GST for proper record keeping and share financial reports with clients and accountants.",
      buttonn: {
        to: "/",
        href: "#",
        text: "See More",
      },
    },
    {
      image: {
        path: "business-by-industry--e-commerce",
        alt: "business-by-industry--e-commerce",
      },
      title: "Creative professionals",
      description:
        "Effortlessly manage your expenses and income. With Synkli, you won’t be missing any of your business deductions. You can enjoy sharing your financial summaries with your accountant for a clear understanding.",
      buttonn: {
        to: "/",
        href: "#",
        text: "See More",
      },
    },
    {
      image: {
        path: "business-by-industry--retail",
        alt: "business-by-industry--retail",
      },
      title: "Hospitality and food services",
      description:
        "Synkli tracks all your business expenses and income with features that are easy to use. You can record your business activities and scan all necessary receipts. This will help you with your deductions when it is time for tax returns.",
      buttonn: {
        to: "/",
        href: "#",
        text: "See More",
      },
    },
    {
      image: {
        path: "business-by-industry--transport",
        alt: "business-by-industry--transport",
      },
      title: "Transport and automotive",
      description:
        "With the Synkli app, you can track your business trips automatically while recording your income and expenses. Since mileage tracking has been made easy, your financial records can be accurate, as the tax office suggests.",
      buttonn: {
        to: "/",
        href: "#",
        text: "See More",
      },
    },
    {
      image: {
        path: "business-by-industry--dayactivities",
        alt: "business-by-industry--dayactivities",
      },
      title: "Day to day activities",
      description:
        "Individuals can record their personal expenses and income while ensuring accurate records of their business deductions on the side. Scan your receipts on the go and share all important financial statements with your accountant during tax time.",
      buttonn: {
        to: "/",
        href: "#",
        text: "See More",
      },
    },
  ]
}

// BusinessModelsWithForm Section
export const businessModelsWithFormSection = {
  title: `Synkli – Your Ultimate Business Management Partner`,
  description: `Elevate your business with Synkli, your comprehensive business management solution. Streamline operations, enhance efficiency, and drive growth with our intuitive software.`,
  formSubmitButtonText : `Get Resources`
}

// Testimonial Section
export const testimonialSection = {
  title: `Hear from our customers directly`,
  description: `What about getting first hand information from people who have used our app? Read how we have transformed individuals from novices to experts in their financial management. Our satisfied clients experience a better financial lifestyle, which can be you too.`
}

// Case Study Section
export const caseStudiesSection = {
  title: `Case Studies`,
  description: `Discover how our app has transformed business's and individuals' stories into success experiences. From diverse industries, learn how Synkli has simplified financial management, made tax deductions easier, and improved communication with accountants.`
}

// Happy Customer Section
export const happyCustomerSection = {
  subTitle: `Happy Customer`,
  mainTitle: `A Day In The Life of a Satisfied Customer`,
  title: `Improving client relationships and standing out against the crowd`,
  description: `“Our financial planning is not easy to handle. Every employee grunts when it’s time to arrange receipts, manually record incomes and expenses or prepare for tax season. However, since introducing Synkli to our company, everyone has been interested and involved. Clients, employees and our accountants are happy.”`
}

// Faqs Section
export const faqs = [
  {
    question: "Who can use the app?",
    answer:
      "Synkli is designed for a wide range of users. Whether you are a business owner, salaried individual, rental property owner, sole trader, small business owner, or self-employed, you can use the app to manage your finances efficiently.",
  },
  {
    question: "Do I need help logging in as a first-time user?",
    answer:
      "No assistance is necessary for a first-time user because the app is designed with a simple to understand interface. You can easily download the app, sign up, and log in on your own. Once logged in, you can start your record keeping and connect with your accountant seamlessly, making the process straightforward and user-friendly.",
  },
  {
    question: "Is Synkli only for small businesses?",
    answer:
      "No, Synkli is not limited to small businesses. It is also suitable for salaried individuals, rental property owners, and sole traders. It provides financial management tools for a diverse range of users.",
  },
  {
    question: "If I don’t understand a feature, who can I contact?",
    answer:
      "If you encounter any issues or have questions about a feature, you can reach out to us through our support portal. Our dedicated support team will assist you promptly. Additionally, you can email us directly at support@synkli.com.au for help.",
  },
  {
    question: "What’s the limit for the number of accountants I can add?",
    answer:
      "There is no restriction on the number of accountants you can connect with on Synkli. You can add as many accountants as you need to manage your financial affairs.",
  },
  {
    question: "Do I need to subscribe?",
    answer:
      "Synkli offers both free and premium features. While some advanced features require a subscription, they all come with a 30-day free trial period. This allows you to explore and evaluate the app's full capabilities before deciding to subscribe.",
  },
  {
    question:
      "Is Synkli's accounting software affordable for small businesses?",
    answer:
      "Yes, Synkli offers competitively priced accounting packages designed to fit the budget of small businesses without compromising on quality or functionality.",
  },
  {
    question: "How secure is my financial data with Synkli?",
    answer:
      "We prioritise your data security. Synkli uses top industry practices to ensure your financial data is protected. All your information is encrypted, giving you peace of mind as you manage your finances through the app.",
  },
  {
    question: "Can I import existing financial data into the app?",
    answer:
      "Yes, Synkli has a unique onboarding system that facilitates the easy import of your existing financial data. This helps you create your profile quickly and ensures a smooth transition to using the app.",
  },
  {
    question: "How can I calculate my business profit and loss?",
    answer:
      "Synkli's business deduction tool makes calculating your profit and loss simple. Set up your business profile and start inputting your income and expenses. The app will automatically calculate your profit and loss based on your provided information.",
  },
  {
    question: "Can the app help me with deductions during tax time?",
    answer:
      "Absolutely. Synkli is designed to help you maximise your tax refunds. The app's deduction tools allow you to upload expense deductions easily from anywhere, ensuring you get all claimable deductions. You can send all your deduction claims to your accountant during tax time to optimise your tax refund.",
  },
  {
    question: "Does the app support multiple currencies?",
    answer:
      "Currently, Synkli only supports the Australian dollar and is tailored to meet the requirements of the Australian Taxation Office.",
  },
  {
    question: "Can I manage both business and personal expenses with the app?",
    answer:
      "Yes, you can manage both business and personal expenses with Synkli. The app lets you set up separate profiles for your business and individual finances. You can upload your expense deductions to the appropriate profile, making tracking business and personal financial activities easy. When you upload your deductions to your personal or business account, you can then send them to your accountant for a complete claim of your business and personal deductions.",
  },
  // More questions...
]

// MobileAppLinks Section
export const mobileAppLinksSection = {
  title: `Maximising your tax returns with easy deduction claims`,
  description: `Download our mobile app to ensure you have accurate record keeping and an increase in your tax refunds. With Synkli, you can explore many accounting tools and features.`
}
