import React from "react"
import PageHeroSection from "../../common/PageHeroSection"
import ImageRenderer from "../../atoms/ImageRenderer"

const HeroSection = ({title, description, image}) => {
  return (
    <div className="synkli-section--horizontal-space py-10">
      <PageHeroSection
        title={title}
        description={description}
        descriptionEC={`max-w-[1150px]`}
      />
      <div className="text-center mt-14 max-w-[1430px] mx-auto px-6">
        <ImageRenderer img={image.path} alt={image.alt} />
      </div>
    </div>
  )
}

export default HeroSection
