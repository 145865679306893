import React from "react"
import PageSectionHeader from "../../common/PageSectionHeader"
import BusinessCard from "../../molecules/BusinessCard"

const BusinessByIndustrySection = ({subTitle, title, industries}) => {
  return (
    <div className="synkli-section--horizontal-space synkli-section--vertical-space !pb-0">
      <PageSectionHeader subTitle={subTitle} title={title} />
      <div className="flex flex-col md:flex-row flex-wrap justify-center gap-6 items-stretch mt-16">
        {industries.map((business, index) => (
          <BusinessCard
            key={"business_" + index}
            businessCardEC={`md:w-[32%] mb-6 sm:mb-8`}
            image={{
              path: business.image.path,
              alt: business.image.alt,
            }}
            title={business.title}
            description={business.description}
            // button={{
            //   to: business.buttonn.to,
            //   href: business.buttonn.href,
            //   text: business.buttonn.text,
            // }}
          />
        ))}
      </div>
    </div>
  )
}

export default BusinessByIndustrySection
